import { render, staticRenderFns } from "./departamento-dragable.vue?vue&type=template&id=7417fc5d&scoped=true&"
import script from "./departamento-dragable.vue?vue&type=script&lang=js&"
export * from "./departamento-dragable.vue?vue&type=script&lang=js&"
import style0 from "./departamento-dragable.vue?vue&type=style&index=0&id=7417fc5d&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7417fc5d",
  null
  
)

export default component.exports