<template>
  <b-card no-body>
    <b-form-group
      label=""
      label-align-sm="right"
      label-for="filterInput"
      class="mb-0"
    >
      <b-input-group>
        <b-form-input
          id="filterInput"
          v-model="filter"
          @keyup.enter="busqueda"
          type="search"
          placeholder="Buscar.."
        />
      </b-input-group>
    </b-form-group>
    <b-form-group :label="labelBusqueda">
      <b-form-checkbox
        v-for="departamento in items"
        :key="departamento.id"
        v-model="elements"
        :value="departamento"
        name="flavour-3a"
      >
        {{ departamento.nombre }}
      </b-form-checkbox>
    </b-form-group>
    
  </b-card>
</template>
<script>
import axios from "@axios";
import {
  BCard,
  BCardText,
  BTable,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BDropdown,
  BDropdownItem,
  BOverlay,
  BFormCheckbox,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
export default {
  components: {
    BCard,
    BCardText,
    BTable,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    BOverlay,
    vSelect,
    BFormCheckbox,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      items: [],
   
      selected: [],
      filter: null,
      filterOn: [],
      perPage: 10,
      pageOptions: [10, 25, 50],
      totalRows: 1,
      currentPage: 1,
      loading: false,
    
      item: [],
    };
  },
  computed: {
    labelBusqueda() {
      return this.filter ? "Resultados para " + this.filter : "";
    },
     elements: {
      get() {
        return this.$store.state.organigrama.organigrama_elementos;
      },
      set(value) {
        this.$store.dispatch("organigrama/updateElementsOrganigrama", value);
      }
    }
  },

  created() {
    this.$store.dispatch("organigrama/updateElementsOrganigrama", []);
    this.loading = true;
  },
  methods: {
    busqueda() {
      
      const variable = `?busqueda=${this.filter}`;
      //console.warn(variable);
      axios
        .get(`/departamentos${variable}`)
        .then((res) => {
          this.items = res.data.data;

          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

  },
};
</script>
