<template>
  <b-row>
    <b-col md="4" lg="4">
      <b-card>
        <b-card-body>
          <app-collapse accordion>
            <app-collapse-item title="Departamentos">
              <Departamentos></Departamentos>
            </app-collapse-item>
          </app-collapse>
        </b-card-body>
      </b-card>
    </b-col>
    <b-col md="6" lg="6">
      <b-card no-body>
        <b-card-body>
          <validation-observer ref="simpleRules">
            <b-form>
              <b-row>
                <!-- fecha -->
                <!-- <b-col md="12">
              <b-form-group
                label="Nombre Grupo Enlaces"
                label-for="nombre-menu"
              >
                <b-form-input
                  id="nombre-menu"
                  placeholder="Introduzca Nombre del Grupo de Enlaces"
                  v-model="menu.nombre"
                />
              </b-form-group>
            </b-col> -->

                <b-col cols="12">
                  <b-form-group
                    
                    label-for="nombre-menu"
                  >
                  <label for="nombre-menu">Nombre Organigrama<span class="text-danger">(*)</span></label>
                    <validation-provider
                      #default="{ errors }"
                      name="Nombre Menu"
                      rules="required|min:4"
                    >
                      <b-form-input
                        id="nombre-menu"
                        placeholder="Introduzca Nombre del Organigrama"
                        :state="errors.length > 0 ? false : null"
                        v-model="menu.nombre"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small
                        v-for="error in errores.nombre"
                        :key="error"
                        class="text-danger"
                        >{{ error }}</small
                      >
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label="Estado" label-for="Estado">
                    <v-select
                      id="blog-edit-estado"
                      v-model="estado"
                      label="title"
                      :options="estadoOpciones"
                      :clearable="false"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <h6 class="text-primary font-weight-bold mb-2">
                    Items del Grupo
                  </h6>

                  <!-- draggable -->
                  <departamento-dragable v-model="elements" />
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" class="mt-50">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mr-1"
                    @click="validar"
                  >
                    Guardar
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    :to="{ name: 'organigramas' }"
                  >
                    Cancelar
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card-body>
      </b-card>
    </b-col>
    <b-col md="2" lg="2">
      <b-card no-body>
        <b-card-body>
          <b-row>
            <b-col md="12">
              <h6 class="text-primary font-weight-bold mb-2">
                Items Eliminados
              </h6>

              <!-- draggable -->

              <draggable :list="list2" group="a">
                <div
                  class="bg-secondary text-light list-group-item item mb-1"
                  v-for="element in list2"
                  :key="element.id"
                >
                  {{ element.etiqueta }}
                </div>
              </draggable>
            </b-col>
            <b-row>
              <b-col cols="12" class="mt-50">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="danger"
                  class="mr-1"
                  @click="eliminar"
                >
                  Vaciar
                </b-button>
              </b-col>
            </b-row>
          </b-row>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import axios from "@axios";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import Departamentos from "@/views/aplicaciones/organigrama/departamento/Departamentos.vue";

import DepartamentoDragable from "@/views/aplicaciones/organigrama/menudraggable/departamento-dragable.vue";

import {
  BInputGroup,
  BTabs,
  BTab,
  BCard,
  BCardText,
  BButton,
  BRow,
  BCol,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BLink,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
  BImg,
  BFormFile,
  BFormDatepicker,
  BListGroupItem,
  BFormCheckbox,
} from "bootstrap-vue";
import draggable from "vuedraggable";

import Ripple from "vue-ripple-directive";
import { required } from "@validations";
import vSelect from "vue-select";
import router from "@/router";

export default {
  components: {
    BInputGroup,
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    vSelect,
    BImg,
    BFormFile,
    BFormDatepicker,
    BFormCheckbox,

    BTabs,
    BTab,
    AppCollapse,
    AppCollapseItem,

    Departamentos,

    BListGroupItem,

    draggable,
    DepartamentoDragable,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      errores: [],
      estadoOpciones: [
        { id: 0, title: "Inactivo" },
        { id: 1, title: "Activo" },
      ],
      estado: { id: 0, title: "Inactivo" },
      url: "",
      list2: [],
      nombre_enlace: "",
      id: null,
      menu: {
        ubicacion: [],
        nombre: "",
        tipo: "enlace",
        activo: 1,
      },
      ubicaciones: [
        {
          id: 1,
          titulo: "Bloque Acceso Principal",
          value: "menu_principal",
        },
        {
          id: 2,
          titulo: "Bloque Acceso Secundario",
          value: "menu_secundario",
        },
        {
          id: 3,
          titulo: "Bloque Enlaces de Interes",
          value: "menu_superior",
        },
      ],
    };
  },
  computed: {
    elements: {
      get() {
        return this.$store.state.organigrama.elements;
      },
      set(value) {
        this.$store.dispatch("organigrama/updateElements", value);
      },
    },
  },
  created() {
    this.elements = [];
    if (router.currentRoute.params.id) {
      this.getMenu(router.currentRoute.params.id);
      //this.agregarElements(this.menu.menuitems);
    }
  },
  methods: {
    getMenu(id) {
      axios
        .get(`/organigramas/${id}`)
        .then((response) => {
          this.menu = response.data.data;
          this.menu.eliminar = [];
          const valor = this.estadoOpciones.find(
            (estado) => estado.id === response.data.data.estado
          );
          this.estado = { id: valor.id, title: valor.title };

          this.agregarElements(this.menu.organigramatems);
        })
        .catch((error) => console.error(error));
    },
    agregarElements(arrayElementos) {
      arrayElementos.forEach((element) => {
        const d = new Date();
        let time = d.getTime();
        var idtime = element.id + "-" + time;
        console.log(element);

        var itemadd = {
          id: element.id,
          time: element.idtime,
          
          etiqueta: element.nombre,
          titulo: element.titulo,
          icono: element.icono,
          departamento_organigrama_id: element.departamento_organigrama_id,

          organigrama_item_hijas: [],
        };
        this.anadir_items_hijas(itemadd, element.organigrama_item_hijas);
        this.$store.dispatch("organigrama/addElements", itemadd);
      });
    },
    anadir_items_hijas(item, hijos) {
      hijos.forEach((element) => {
        const d = new Date();
        //let time = d.getTime();
        var idtime = element.idtime;
        var itemadd = {
          id: element.id,
          time: idtime,
          
          etiqueta: element.nombre,
          titulo: element.titulo,
          icono: element.icono,
          departamento_organigrama_id: element.departamento_organigrama_id,
          organigrama_item_hijas: [],
        };
        item.organigrama_item_hijas.push(itemadd);
        if (element.organigrama_item_hijas)
          this.anadir_items_hijas(itemadd, element.organigrama_item_hijas);
      });
    },
    eliminar() {
      if (this.menu.id)
        this.menu.eliminar = this.menu.eliminar.concat(this.list2);
      this.list2 = [];
    },
    validar() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.guardar();
        }
      });
    },
    async guardar() {
      this.menu.organigramatems = this.elements;
      this.menu.estado = this.estado.id;
      if (this.menu.id) {
        this.menu.eliminar = this.menu.eliminar.concat(this.list2);

        await axios
          .put(`/organigramas/${this.menu.id}`, this.menu)
          .then((response) => {
            this.success("Actualizado con Exito");
          })
          .catch((error) => {
            if (error.response.status === 422) {
              this.errores = error.response.data.errors;
            } else {
              console.error(error);
            }
          });
      } else {
        axios
          .post("/organigramas", this.menu)
          .then((response) => {
            this.elements = [];
            this.success("Insertado con Exito");
          })
          .catch((error) => {
            if (error.response.status === 422) {
              this.errores = error.response.data.errors;
            } else {
              console.error(error);
            }
          });
      }
    },
    async success(Mensaje) {
      await this.$swal({
        title: Mensaje,

        icon: "success",
        timer: 2000,
        confirmButtonText: "Aceptar",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
      router.push({ name: "organigramas" });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>